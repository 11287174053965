:root {
	--haiti: #0b132b;
	--cloudBurst: #1c2541;
	--fiord: #3a506b;
	--fountainBlue: #5bc0be;
	--darkFountainBlue: #407a79;
}

#case_ifsp {
	#header {
		background-color: var(--fiord);
	}
	.fileSection {
		background-color: gray;
	}

	.container-fluid {
		background-color: white;
	}
}
body {
	/* background-color: white; */
}
