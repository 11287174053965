/* body {
	color: white;
} */
#inbox {
	h2 {
		color: white;
		margin-left: 70px;
		font-size: 50px;
	}
	p {
		font-size: large;
		text-align: center;
		padding-bottom: 0px;
		margin-bottom: 0;
	}
	.list-item {
		padding-left: 10%;
		background-color: white;
		margin-top: 5px;
		margin-bottom: 5px;
		height: 50px;
		text-align: center;
		font-size: 25px;
		font-weight: bold;
	}
	.list-header {
		color: white;
		font-size: 30px;
		/* margin-left: 100px; */
		padding-left: 10%;
		text-align: center;
	}
	label {
		color: white;
		margin-left: 15px;
		margin-right: 15px;
		font-weight: bolder;
	}
}
