:root {
	--haiti: #0b132b;
	--cloudBurst: #1c2541;
	--fiord: #3a506b;
	--fountainBlue: #5bc0be;
	--darkFountainBlue: #407a79;
}
.container-fluid {
	padding: 0px;
	background-color: var(--fiord);
	height: 100%;
}
body {
	background-color: var(--fiord);
}
header {
	padding: 5px;
	margin-top: 15px;
	margin-right: 5px;
	color: white;
	font-weight: bold;
	#filterSort {
		margin-left: 2px;
		/* margin-right: 5px; */
		font-weight: 200;
		text-decoration: underline;
		margin-right: 20px;
	}
}

#case {
	#search {
		input {
			width: 100%;
			margin-bottom: 5px;
		}
	}
	#content {
		padding-left: 18px;
		padding-right: 18px;
	}
	.demographicMenu {
		padding: 5px;
		background-color: white;
		margin-bottom: 10px;
		margin-right: 10px;
		button {
			background-color: var(--fountainBlue);
			border-radius: 5px;
		}
	}
	#careTeam {
		margin-bottom: 0px;
	}
	#caseMenu {
		padding-top: 5px;
		a {
			text-decoration: none;
			color: var(--cloudBurst);
		}
	}
	.caseMenuItem {
		color: var(--cloudBurst);
		background-color: #d9d9d9;
		margin: 10px 10px 5px 10px;
		border-radius: 8px;
		padding-top: 6px;
		padding-bottom: 6px;
		display: flex;
		/* justify-content: space-between; */
		align-items: center;
	}
	.caseMenuItem:hover {
		background-color: var(--fountainBlue);
	}
	.arrow-icon {
		font-size: 40px; /* Keep the size as needed */
		/* Initially hide the icon */
		display: none;
	}

	.caseMenuItem:hover .arrow-icon {
		display: inline-block; /* Show the icon on hover */
	}
	label {
		color: white;
		padding-right: 7px;
	}
}
